import { useStore } from '@nanostores/react'
import { isCartOpen, cartData } from '@stores/cartStore'
import { isSearchOpen } from '@stores/searchStore'
import { isMegaMenuOpen } from '@stores/menuStore'
import { hasCartQuantityError } from '../../stores/cartStore'

const Overlay = () => {
    const $isCartOpen = useStore(isCartOpen || false)
    const $isSearchOpen = useStore(isSearchOpen || false)
    const $isMegaMenuOpen = useStore(isMegaMenuOpen || false)

    const handleOverlayClick = () => {
        if ($isCartOpen) {
            isCartOpen.set(false)
            hasCartQuantityError.set(false)
        }
        if ($isSearchOpen) {
            isSearchOpen.set(false)
        }
        if ($isMegaMenuOpen) {
            isMegaMenuOpen.set(false)
        }
    }

    return (
        <div
            className={`overlay ${$isCartOpen ? 'show-overlay' : ''} ${
                $isSearchOpen ? 'show-overlay' : ''
            } ${$isMegaMenuOpen ? 'show-overlay' : ''}`}
            onClick={handleOverlayClick}
        ></div>
    )
}

export default Overlay
